import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Navigation from "./navigation";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "../styles/normalize.css";
import "./layout.scss";

const Layout = ({ page, children, bodyClassname }) => {
  if (typeof window != "undefined") {
    window.addEventListener("load", function() {
      setTimeout(function() {
        // This hides the address bar:
        window.scrollTo(0, 1);
      }, 0);
    });
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        let pageClassname = page;
        if (bodyClassname) {
          pageClassname = bodyClassname;
        }
        return (
          <Container
            className={"content-container h-100 page page-" + pageClassname}
          >
            <Helmet />
            <div>{children}</div>
            <Navigation />
          </Container>
        );
      }}
    />
  );
};

Layout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node.isRequired,
  bodyClassname: PropTypes.string
};

export default Layout;
