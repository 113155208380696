import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import "./navigation.scss";

const NavLink = ({}) => <div />;

const Navigation = ({ siteTitle }) => (
  <div className="component-navigation">
<Col>
        <Row>
    <Navbar expand="md" className="col-12">
      <Navbar.Toggle aria-controls="basic-navbar-nav" className=""/>
      <Navbar.Collapse id="basic-navbar-nav">
          <Col className='col-1'/>
          <Col>
            <AniLink fade className="" to="/" duration={0.35}>
              Home
            </AniLink>
          </Col>
          <Col>
            <AniLink fade className="" to="/rsvp/" duration={0.35}>
              RSVP
            </AniLink>
          </Col>
          <Col>
            <AniLink fade className="" to="/wedding/" duration={0.35}>
              Wedding
            </AniLink>
          </Col>
          <Col>
            <AniLink fade className="" to="/things-to-do/" duration={0.35}>
              Places
            </AniLink>
          </Col>
          <Col>
            <AniLink fade className="" to="/lodging/" duration={0.35}>
              Lodging
            </AniLink>
          </Col>
          <Col>
            <AniLink fade className="" to="/registry/" duration={0.35}>
              Registry
            </AniLink>
          </Col>
        <Col className='col-1'/>
      </Navbar.Collapse>
    </Navbar>
  </Row>
</Col>
  </div>
);

Navigation.propTypes = {
  currentPage: PropTypes.string
};

Navigation.defaultProps = {
  currentPage: ""
};

export default Navigation;
