import React from "react";
import {
  Link
} from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import "./index.scss";

const IndexPage = () => (
    <Layout page='index'>
      <SEO title="Home" />
      <Row className='save-the-date'>
        <Col sm>
          <h2>Please join us<br/>
            For the Wedding of
          </h2>
          <h1>Marie Clare Brush<br />
            & Andrew Mahon</h1>
          <h2>November 9, 2019<br />
            In Westport, California
          </h2>
        </Col>
      </Row>
    </Layout>
);

export default IndexPage;
